import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AuthService } from "../../../auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppSettings } from "../../../../environments/environment";
import { EnvConfigService } from '../../../env-config.service'

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  logo_url = AppSettings.logo_URL
  logo_width = AppSettings.logo_width
  logo_height = AppSettings.logo_height
  logoUrl: any;
  user: any;
  isDarkMode = false;

  userMenu = [{ title: this.translate.instant("HEADERS.PROFILE") }, { title: this.translate.instant("HEADERS.LOGOUT") }];

  constructor(
    private readonly envConfig: EnvConfigService,
    private readonly sidebarService: NbSidebarService,
    private readonly menuService: NbMenuService,
    private readonly themeService: NbThemeService,
    private readonly translate: TranslateService,
    private readonly breakpointService: NbMediaBreakpointsService,
    private readonly _auth: AuthService,
    private readonly router: Router
  ) {
    this.user = JSON.parse(localStorage.getItem("AdminDetails"));
    const logo = this.envConfig.getEnvData()
    this.logoUrl = logo?.app?.logo
    console.log(this.logoUrl, "Url");
  }

  ngOnInit() {
    const savedTheme =
      localStorage.getItem('theme') === 'default' || localStorage.getItem('theme') === 'dark'
        ? localStorage.getItem('theme')
        : 'default';

    this.isDarkMode = savedTheme === 'dark';
    this.themeService.changeTheme(savedTheme);


    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.menuService.onItemClick().subscribe((e) => {
      if (e.item.title === this.translate.instant("HEADERS.LOGOUT")) {
        // localStorage.clear();
        this._auth.logout();
        // localStorage.clear();
      } else if (e.item.title === this.translate.instant("HEADERS.PROFILE")) {
        this.router.navigate(["/admin/admin/admin-profile"]);
      }
    });
  }

  handleKeyDown(event: KeyboardEvent): void {
    console.log('Key pressed:', event.key);
    if (event.key === 'Enter') {
      this.notificationroute();
    }
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  routring() {
    this.router.navigateByUrl("/admin/expiry-details");
  }
  notificationroute() {
    this.router.navigateByUrl("/admin/notifications")
  }

  toggleTheme(): void {
    this.isDarkMode = !this.isDarkMode;
    const theme = this.isDarkMode ? 'dark' : 'default';
    this.themeService.changeTheme(theme);
    localStorage.setItem('theme', theme);
  }
}
