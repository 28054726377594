import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "./admin/api.service";
import { DataService } from "./admin/data.service";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  
  constructor(private readonly _service: ApiService, private readonly router: Router, private readonly dataService: DataService) {}
  
  login(data: any) {
    this._service.CommonPostApi(data, "common/login/admin").subscribe({
      next: (res) => {
        localStorage.setItem("AdminDetails",JSON.stringify(res.data.admin))
        localStorage.setItem("token", res.data.token);
        this.router.navigate(["/admin/dashboard"]);
        // setTimeout(() => {
        // this.router.navigate(["/admin/dashboard"]);
        // }, 2000);
      },
      error: (error) => {
        this.router.navigate(["/auth/login"]);
      },
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["/auth/login"]);
  }

  hasPermission(permission: string): boolean {
    const authUserDetails = JSON.parse(localStorage.getItem("AdminDetails") || '{}');
    const getUserPermissions = authUserDetails?.group?.permission || [];

    const userPermissions: string[] = [];

    const extractPermissions = (permissions: any[]) => {
        permissions.forEach(permissionItem => {
            if (permissionItem.status) {
                userPermissions.push(permissionItem.menu);
                if (permissionItem.subMenu && permissionItem.subMenuList.length > 0) {
                    extractPermissions(permissionItem.subMenuList);
                }
            }
        });
    };

    extractPermissions(getUserPermissions);

    console.log(userPermissions, "userPermissions");
    return userPermissions.includes(permission);
}



}
