<div class="row">
    <div class="col-md-12">
      <nb-card>
        <nb-card-body>
          <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
            <h2 class="title">403</h2>
            <h5 class="text-center">Access Denied</h5>
            <small class="sub-title">Sorry, you don't have permission to access this page.</small>
            <button nbButton fullWidth  type="button" class="home-button" (click)="goToProfile()">
              Take me to profile
            </button>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
  