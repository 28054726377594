import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { InterceptorService } from './interceptor.service';
import { SmarttableService } from './admin/smarttable.service';


declare var Pace: any;

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private readonly interceptorService: InterceptorService,     // Global loading management
    private readonly smarttableService: SmarttableService        // Specific table loading management
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(req,"Requestttt");
    
    // Check if the URL contains 'page' and 'limit' query parameters
    const hasPaginationParams = req.urlWithParams.includes('page') && req.urlWithParams.includes('limit');
    this.interceptorService.show();
    if (hasPaginationParams) {
      this.smarttableService.show();
    }
    Pace.restart();
    return next.handle(req).pipe(
      finalize(() => {
        // Hide both loading indicators
        this.interceptorService.hide();
        if (hasPaginationParams) {
          this.smarttableService.hide();
        }
        Pace.stop();
      })
    );
  }
}
