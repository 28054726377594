<div class="row">

  <nb-layout-column class="col-lg-6">
    <img src="assets/theme/account/forgot.svg" class="img-fluid" alt="" width="310" height="500">
  </nb-layout-column>
  <nb-layout-column class="col-lg-6">
    <h3 class="tx-color-01 mg-b-5 " id="title"> <button nbTooltipStatus="danger" nbTooltip="{{'GoBack'}}"
        nbTooltipPlacement="bottom" (click)="goBackBtn()" nbButton ghost status="danger" style="padding: 0px;">
        <nb-icon icon="arrow-back-outline"></nb-icon>
      </button> Forget Password</h3>
    <p class="tx-color-01 mg-b-5">Enter your email address and we’ll send a link to reset your password</p>


    <form [formGroup]="forgetPasswordForm" (ngSubmit)="requestPassword()">
      <div class="form-control-group">
        <label class="label" for="input-email">Email address:</label>
        <input nbInput fullWidth name="email" id="input-email" pattern=".+@.+\..+" placeholder="Enter Email address"
          fieldSize="large" autofocus formControlName="email" />
        <ng-container *ngIf="forgetPasswordForm.controls.email.invalid && forgetPasswordForm.controls.email.touched">
          <p class="caption status-danger" *ngIf="forgetPasswordForm.controls.email.errors?.required">
            Email is required!
          </p>
          <p class="caption status-danger" *ngIf="forgetPasswordForm.controls.email.errors?.pattern">
            Email should be the real one!
          </p>
        </ng-container>
      </div>


      <button nbButton fullWidth status="primary" size="large" [disabled]="forgetPasswordForm.invalid">
        Request Password
      </button>
    </form>
  </nb-layout-column>
</div>