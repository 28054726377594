import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyapiService {

  constructor(private readonly http: HttpClient) { }

  CommonGetApi(path: string): Observable<any> {
    return this.http.get<any>(environment.API_ENDPOINT + path);
  }
  CommonGetApiwithparams(path: string, params: any): Observable<any> {
    return this.http.get<any>(environment.API_ENDPOINT + path, { params: params });
  }
  CommonPostApi(inputs: any, path: string): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + path, inputs);
  }
  CommonPostOneApiwithParams(path: string, id: string): Observable<any> {
    return this.http.post<any>(
      environment.API_ENDPOINT + path + "" + `?id=` + id,
      {}
    );
  }
  CommonLoginPostApi(inputs: any, path: string): Observable<any> {
    const headers = new HttpHeaders().set("deviceid", "Abservetech@27");
    return this.http.post<any>(environment.API_ENDPOINT + path, inputs, {
      headers: headers,
    });
  }

  CommonPatchApi(inputs: any, path: string): Observable<any> {
    return this.http.patch<any>(environment.API_ENDPOINT + path, inputs);
  }

  CommonPutApi(path: string, inputs: any): Observable<any> {
    return this.http.put<any>(environment.API_ENDPOINT + path, inputs);
  }

  CommonDeleteApi(id: any, path: string): Observable<any> {
    return this.http.delete<any>(environment.API_ENDPOINT + path + "/" + id);
  }
  EmailVerificationGetApi(data: string, path: string): Observable<any> {
    return this.http.get<any>(
      environment.API_ENDPOINT + path + "/" + "exists?email=" + data
    );
  }

  PhoneVerificationGetApi(
    phone: string,
    phoneCode: string,
    path: string
  ): Observable<any> {
    return this.http.get<any>(
      environment.API_ENDPOINT +
        path +
        "/" +
        "exists?phone=" +
        phone +
        "&phoneCode=" +
        phoneCode
    );
  }

  openStreetmapGetApi(city: string): Observable<any> {
    return this.http.get<any>(
      "https://nominatim.openstreetmap.org/search.php?q=" +
        city +
        "&polygon_geojson=1&format=json"
    );
  }

  downloadExcelFile(api: string, fileName: string): void {
    this.http
      .get(environment.API_ENDPOINT + api, {
        observe: "response",
        responseType: "blob",
      })
      .subscribe((response) => {
        console.log(
          "All Headers:",
          response.headers.keys(),
          response.headers.get("X-Filename")
        );
        const blob = response.body;
        console.log("BLOB", blob);

        // Extract filename from Content-Disposition header
        // const contentDispositionHeader = response.headers.get(
        //   "Content-Disposition"
        // );
        // debugger;
        let filename = fileName; // Default filename
        const filenameHeader = response.headers.get("x-filename");
        if (filenameHeader) {
          filename = filenameHeader;
        }
        // if (contentDispositionHeader) {
        //   const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        //   const matches = filenameRegex.exec(contentDispositionHeader);
        //   if (matches != null && matches[1]) {
        //     filename = matches[1].replace(/['"]/g, "");
        //   }
        // }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
  }

  getISOFormattedDateTime(dateObj: any, timeStr: string): string {
    if (!dateObj || !timeStr) {
      console.error('Invalid date or time object:', { dateObj, timeStr });
      return '';
    }
  
    try {
      const { year, month, day } = this.parseDate(dateObj);
      const { hours, minutes } = this.parseTime(timeStr);
      return this.createISODate(year, month, day, hours, minutes);
    } catch (error) {
      console.error('Error formatting date and time:', error);
      return '';
    }
  }
  
  // Extract date values from the input object
  parseDate(dateObj: any): { year: number; month: number; day: number } {
    const year = dateObj.year || new Date(dateObj).getFullYear();
    const month = dateObj.month ? dateObj.month - 1 : new Date(dateObj).getMonth(); // 0-indexed
    const day = dateObj.day || new Date(dateObj).getDate();
  
    if (!year || month < 0 || !day) {
      throw new Error('Invalid date values');
    }
  
    return { year, month, day };
  }
  
  // Extract time values from the input string
  parseTime(timeStr: string): { hours: number; minutes: number } {
    let Regex = /^(\d{2}):(\d{2})$/;
    const time24Hour = Regex.exec(timeStr);
    if (time24Hour) {
      return { hours: parseInt(time24Hour[1], 10), minutes: parseInt(time24Hour[2], 10) };
    }
    let regex = /(\d+):(\d+)\s?(AM|PM)/i;
    const time12Hour = regex.exec(timeStr);
    if (time12Hour) {
      let hours = parseInt(time12Hour[1], 10);
      const minutes = parseInt(time12Hour[2], 10);
      const ampm = time12Hour[3].toUpperCase();
  
      if (ampm === 'PM' && hours < 12) hours += 12;
      if (ampm === 'AM' && hours === 12) hours = 0;
  
      return { hours, minutes };
    }
  
    throw new Error('Invalid time string format');
  }
  
  // Create and validate the ISO date
  createISODate(year: number, month: number, day: number, hours: number, minutes: number): string {
    const localDate = new Date(year, month, day, hours, minutes);
    if (isNaN(localDate.getTime())) {
      throw new Error('Invalid date object created');
    }
    return localDate.toISOString();
  }
  
}
