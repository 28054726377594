import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngxPlural' })
export class PluralPipe implements PipeTransform {
  formattedLabel : any;
  transform(input: number, label: string, pluralLabel: string = ''): string {
    input = input || 0;
    // return input === 1
    //   ? `${input} ${label}`
    //   : pluralLabel
    //     ? `${input} ${pluralLabel}`
    //     : `${input} ${label}s`;
    
    if(input === 1){
      this.formattedLabel = `${input} ${label}`;
    }else if(pluralLabel){
      this.formattedLabel = `${input} ${pluralLabel}`;
    }else{
      this.formattedLabel = `${input} ${label}s`;
    }
    return this.formattedLabel
  }
}
