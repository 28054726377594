import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-no-access-page',
  templateUrl: './no-access-page.component.html',
  styleUrl: './no-access-page.component.scss'
})
export class NoAccessPageComponent {
  constructor(private router: Router) {}

  goToProfile() { 
    this.router.navigate(['admin/admin/admin-profile']);
  }

}
